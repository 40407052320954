import { ConfigProvider, Modal, Popover, Steps, Tag, Timeline } from "antd";
import Typography from "app/shared/Typography";
import React, { useEffect, useState } from "react";
import "./LeadDetails.scss";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import { colorPicker } from "app/utils/helpers/color.helper";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import { FormattedNumber } from "react-intl";
import { CallEndOutlined } from "@mui/icons-material";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import { FlagOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import LogLeadActivity from "./components/LogLeadActivity";
import { apiGet, apiPut } from "app/services/apiService";
import { parseURL } from "app/utils/helpers/common.helper";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import {
  API_ENDPOINT_LEAD_ACTIVITIES,
  API_ENDPOINT_LEAD_FETCH_INFO,
  API_ENDPOINT_LEAD_UPDATE_LEAD_ASSIGNEE,
  API_ENDPOINT_LEAD_UPDATE_LEAD_STATUS,
} from "../../leads.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import InputRoundedIcon from "@mui/icons-material/InputRounded";
import Loader from "app/shared/Loader";
import moment from "moment";
import { useNavigate, useParams } from "react-router";
import SearchSelector from "app/shared/SearchSelector";
import LeadIterests from "./components/LeadInterests";
import LeadUpdate from "./components/LeadUpdate";
import Selector from "app/shared/Selector";
import { LEAD_JOURNEY_ID_MAP } from "app/constants/global.constants";
import LogSkip from "./components/LogSkip";
const activityIconMap = {
  CALL: <PhoneOutlined />,
  EMAIL: <MailOutlined />,
  TASK: <FlagOutlined />,
  EVENT: <FlagOutlined />,
};
export default function LeadDetails(props) {
  const [leadactivity, setLeadActivity] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [leadInfo, setLeadInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalFor, setShowModalFor] = useState("");
  const [selectedLeadStage, setSelectedLeadStage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { leadId } = useParams();

  useEffect(() => {
    fetchLeadInfo();
  }, []);
  const fetchLeadActivity = async () => {
    const response = await apiGet(
      parseURL(API_ENDPOINT_LEAD_ACTIVITIES, {
        leadId: leadId,
      })
    );
    if (response.status) {
      setLeadActivity(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const fetchLeadInfo = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_LEAD_FETCH_INFO + leadId + "/info"
    );
    if (response.status) {
      setLeadInfo(response.data);
      fetchLeadActivity();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
      setLoading(false);
    }
  };

  const getTimelineItems = (listItems) => {
    let items = [];
    listItems.forEach((element) => {
      items.push({
        children: (
          <div
            style={{
              backgroundColor: "#F4F6F9",
              borderRadius: 12,
              padding: 12,
            }}
          >
            <Tag
              color="#108ee9"
              style={{ marginBottom: 6 }}
              icon={activityIconMap[element.type]}
            >
              {element.type}
            </Tag>

            <Typography color="gray-dark" variant="caption">
              {element.description}
            </Typography>
            <div className="ActivityItemList">
              <div className="ActivityItem">
                <TodayOutlinedIcon
                  style={{
                    color: colorPicker("gray_dark"),
                    fontSize: 20,
                    marginRight: 6,
                  }}
                />
                <Typography color="gray-dark" variant="caption">
                  {element.activity_date
                    ? moment(element.activity_date).format(
                        "DD, MMM YYYY, HH:MM A"
                      )
                    : moment(element.updatedAt).format("DD, MMM YYYY, HH:MM A")}
                </Typography>
              </div>
              {element.attachment ? (
                <div
                  className="ActivityItem"
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(element.attachment_url, "_blank")}
                >
                  <AttachFileOutlinedIcon
                    style={{
                      color: colorPicker("gray_dark"),
                      fontSize: 20,
                      marginRight: 6,
                    }}
                  />
                  <Typography color="gray-dark" variant="caption">
                    {element.attachment}
                  </Typography>
                </div>
              ) : null}
            </div>
          </div>
        ),
      });
    });
    return items;
  };

  const handleOpenModal = (type) => {
    setShowModalFor(type);
    setShowModal(true);
  };

  const updatedLeadStage = async (item, skipped = false) => {
    if (leadactivity.length === 0 && !skipped) {
      setSelectedLeadStage(item);
      handleOpenModal("LOG_SKIP");
    } else {
      const response = await apiPut(
        parseURL(API_ENDPOINT_LEAD_UPDATE_LEAD_STATUS, {
          leadId: leadId,
          statusId: item,
        })
      );
      if (response.status) {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 1 },
        });
        fetchLeadInfo();
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 0 },
        });
      }
    }
  };

  const updatedLeadAssignee = async (item) => {
    console.log("item", item);
    const response = await apiPut(
      parseURL(API_ENDPOINT_LEAD_UPDATE_LEAD_ASSIGNEE, {
        leadId: leadId,
        assigneeId: item,
      })
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      fetchLeadInfo();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  const renderModalContent = () => {
    switch (showModalFor) {
      case "CLIENT_INFO":
        return (
          <LeadUpdate
            data={leadInfo && leadInfo}
            onSuccess={() => {
              fetchLeadInfo();
              setShowModal(false);
            }}
          />
        );
      case "CLIENT_INTERESTS":
        return (
          <LeadIterests
            data={leadInfo && leadInfo?.lead_interest}
            intrestId={leadInfo && leadInfo?.lead_interest?.id}
            onSuccess={() => {
              fetchLeadInfo();
              setShowModal(false);
            }}
          />
        );
      case "LOG_SKIP":
        return (
          <LogSkip
            onYes={() => setShowModal(false)}
            onNo={() => {
              updatedLeadStage(selectedLeadStage, true);
              setShowModal(false);
            }}
          />
        );
    }
  };
  return (
    <div className="LeadDetails">
      <div className="LeadStatusJourney">
        <ConfigProvider
          theme={{
            components: {
              Steps: {
                navArrowColor: "#73AF00",
                finishIconBorderColor: "#73AF00",
              },
            },
            token: {
              colorPrimary: "#73AF00",
              colorPrimaryBorder: "#73AF0033",
            },
          }}
        >
          <Steps
            current={LEAD_JOURNEY_ID_MAP[leadInfo?.lead_status?.order]}
            items={[
              {
                title: "Lead Created",
              },
              {
                title: "Sale Contact",
              },
              {
                title: "Property Visit",
              },
              {
                title: "Quotation Shared",
              },
              {
                title: "Closed",
              },
            ]}
          />
        </ConfigProvider>
      </div>
      <div className="LeadDetailContainer">
        <div className="InfoColumn">
          <div className="WidgetContainer">
            <div className="WidgetHeader">
              <Typography variant="h6">Client Info</Typography>
              <BorderColorRoundedIcon
                style={{
                  color: colorPicker("gray_text"),
                  fontSize: 18,
                  cursor: "pointer",
                }}
                onClick={() => handleOpenModal("CLIENT_INFO")}
              />
            </div>
            <div className="WidgetListItem">
              <div className="ListItem">
                <PersonRoundedIcon
                  style={{
                    color: colorPicker("gray_text"),
                    fontSize: 22,
                    marginRight: 6,
                  }}
                />
                <Typography variant="caption">
                  {leadInfo && leadInfo.fullname}{" "}
                </Typography>
              </div>

              <div className="ListItem">
                <LocalPhoneRoundedIcon
                  style={{
                    color: colorPicker("gray_text"),
                    fontSize: 22,
                    marginRight: 6,
                  }}
                />
                <Typography variant="caption">
                  {leadInfo && leadInfo.mobile}{" "}
                </Typography>
              </div>
              {leadInfo && leadInfo?.alternate_mobile ? (
                <div className="ListItem">
                  <LocalPhoneRoundedIcon
                    style={{
                      color: colorPicker("gray_text"),
                      fontSize: 22,
                      marginRight: 6,
                    }}
                  />
                  <Typography variant="caption">
                    {leadInfo && leadInfo.alternate_mobile}{" "}
                  </Typography>
                </div>
              ) : null}

              <div className="ListItem">
                <MailRoundedIcon
                  style={{
                    color: colorPicker("gray_text"),
                    fontSize: 22,
                    marginRight: 6,
                  }}
                />
                <Typography variant="caption">
                  {leadInfo && leadInfo.email}{" "}
                </Typography>
              </div>

              <div className="ListItem">
                <InputRoundedIcon
                  style={{
                    color: colorPicker("gray_text"),
                    fontSize: 22,
                    marginRight: 6,
                  }}
                />
                <Typography variant="caption">
                  {leadInfo && leadInfo.source}{" "}
                </Typography>
              </div>

              <div>
                <Typography variant="xs" color="gray-dark">
                  Promoter
                </Typography>
                <Typography variant="caption">
                  {" "}
                  {leadInfo && leadInfo.creator?.user_profile?.first_name}{" "}
                  {leadInfo && leadInfo.creator?.user_profile?.last_name}{" "}
                </Typography>
              </div>
              <Popover
                content={
                  <div style={{ width: 240 }}>
                    <SearchSelector
                      onChange={updatedLeadAssignee}
                      valueKey={"userId"}
                    />
                  </div>
                }
                trigger="click"
                placement="bottomLeft"
                style={{ width: 300 }}
              >
                <div style={{ marginTop: 18 }}>
                  <Typography variant="xs" color="gray-dark">
                    Assignee
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="caption">
                      {" "}
                      {leadInfo &&
                        leadInfo.assignee?.user_profile?.first_name}{" "}
                      {leadInfo && leadInfo.assignee?.user_profile?.last_name}{" "}
                    </Typography>
                    <ArrowCircleDownIcon
                      style={{ fontSize: 15, marginLeft: 4 }}
                    />
                  </div>
                </div>
              </Popover>

              <Popover
                content={
                  <div style={{ width: 240 }}>
                    <Selector
                      placeholder="Lead Stage"
                      labelKey="status_name"
                      valueKey="id"
                      serviceType="LEAD_STATUS"
                      height={40}
                      onChange={updatedLeadStage}
                    />
                  </div>
                }
                trigger="click"
                placement="bottomLeft"
                style={{ width: 300 }}
              >
                <div style={{ marginTop: 18 }}>
                  <Typography variant="xs" color="gray-dark">
                    Lead Stage
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="caption">
                      <Tag color="blue">
                        {leadInfo && leadInfo.lead_status?.status_name}{" "}
                      </Tag>
                    </Typography>
                    <ArrowCircleDownIcon
                      style={{ fontSize: 15, marginLeft: 4 }}
                    />
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <div className="WidgetContainer">
            <div className="WidgetHeader">
              <Typography variant="h6">Lead Intents</Typography>
              <BorderColorRoundedIcon
                style={{
                  color: colorPicker("gray_text"),
                  fontSize: 18,
                  cursor: "pointer",
                }}
                onClick={() => handleOpenModal("CLIENT_INTERESTS")}
              />
            </div>
            <div className="WidgetListItem">
              <div className="ListItem">
                <CurrencyRupeeRoundedIcon
                  style={{
                    color: colorPicker("gray_text"),
                    fontSize: 22,
                    marginRight: 6,
                  }}
                />
                <Typography variant="caption">
                  {leadInfo && leadInfo.lead_interest?.budget ? (
                    <FormattedNumber
                      value={(leadInfo && leadInfo.lead_interest?.budget) || 0}
                    />
                  ) : (
                    "NA"
                  )}
                </Typography>
              </div>

              <div className="ListItem">
                <LocalShippingOutlinedIcon
                  style={{
                    color: colorPicker("gray_text"),
                    fontSize: 22,
                    marginRight: 6,
                  }}
                />
                <Typography variant="caption">
                  {(leadInfo && leadInfo.lead_interest?.ready_to_move) || "NA"}
                  {leadInfo && leadInfo.lead_interest?.ready_to_move
                    ? " days"
                    : ""}
                </Typography>
              </div>

              <div className="ListItem">
                <LocationOnOutlinedIcon
                  style={{
                    color: colorPicker("gray_text"),
                    fontSize: 22,
                    marginRight: 6,
                  }}
                />
                <Typography variant="caption">
                  {(leadInfo && leadInfo.lead_interest?.location) || "NA"}{" "}
                </Typography>
              </div>

              <div className="ListItem">
                <ApartmentOutlinedIcon
                  style={{
                    color: colorPicker("gray_text"),
                    fontSize: 22,
                    marginRight: 6,
                  }}
                />
                <Typography variant="caption">
                  {(leadInfo && leadInfo.lead_interest?.property_type) || "NA"}{" "}
                </Typography>
              </div>

              <div className="ListItem">
                {leadInfo &&
                leadInfo.lead_interest?.interested_in === "OFF_PLAN" ? (
                  <CurrencyExchangeOutlinedIcon
                    style={{
                      color: colorPicker("gray_text"),
                      fontSize: 22,
                      marginRight: 6,
                    }}
                  />
                ) : (
                  <LocalShippingOutlinedIcon
                    style={{
                      color: colorPicker("gray_text"),
                      fontSize: 22,
                      marginRight: 6,
                    }}
                  />
                )}
                <Typography variant="caption">
                  {(leadInfo &&
                    leadInfo.lead_interest?.interested_in?.replaceAll(
                      "_",
                      " "
                    )) ||
                    "NA"}{" "}
                </Typography>
              </div>
              <div className="ListItem">
                <ApartmentOutlinedIcon
                  style={{
                    color: colorPicker("gray_text"),
                    fontSize: 22,
                    marginRight: 6,
                  }}
                />
                <div>
                  {(leadInfo &&
                    leadInfo?.lead_interest?.lead_liked_properties &&
                    leadInfo?.lead_interest?.lead_liked_properties?.map(
                      (property, key) => {
                        return (
                          <Tag
                            style={{ cursor: "pointer" }}
                            color="#108ee9"
                            onClick={() =>
                              navigate("/property/" + property.propertyId)
                            }
                          >
                            {property?.property?.property_code}
                          </Tag>
                        );
                      }
                    )) ||
                    "NA"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="InfoColumn" style={{ width: "73%" }}>
          <div className="WidgetContainer">
            <div className="WidgetHeader">
              <Typography variant="h6">Log Activity</Typography>
            </div>
            <div className="WidgetContent">
              <LogLeadActivity
                onSuccess={fetchLeadActivity}
                leadHistory={leadactivity}
              />
            </div>
          </div>

          <div className="WidgetContainer">
            <div className="WidgetHeader">
              <Typography variant="h6">Lead History</Typography>
            </div>
            <div
              className="WidgetContent"
              style={{ maxHeight: 400, overflowY: "auto" }}
            >
              <ConfigProvider
                theme={{
                  components: {
                    Timeline: {
                      /* here is your component tokens */
                      color: "#8792A4",
                      tailColor: "#CAD1D8",
                      dotBg: "#8792A4",
                      dotBorderWidth: 0,
                    },
                  },
                }}
              >
                {isLoading ? (
                  <Loader />
                ) : (
                  <Timeline items={getTimelineItems(leadactivity)} />
                )}
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={
          showModalFor === "CLIENT_INFO"
            ? "Client Info"
            : showModalFor === "CLIENT_INTERESTS"
            ? "Client Interests"
            : "Confirmation"
        }
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
}
