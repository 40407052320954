import { Form } from "antd";
import { apiGet, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  API_ENDPOINT_SETTINGS_SCORE_SYSTEM,
  API_ENDPOINT_SETTINGS_SCORE_SYSTEM_FETCH,
  API_ENDPOINT_SETTINGS_UPDATE_USER_INFO,
} from "../../settings.constans";

function ScoreSystem(props) {
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchScoreData = async () => {
    const response = await apiGet(API_ENDPOINT_SETTINGS_SCORE_SYSTEM_FETCH);
    if (response.status) {
      handleInitValues(response.data);
    }
  };
  useEffect(() => {
    fetchScoreData();
  }, [props.userInfo]);

  const handleInitValues = (respData) => {
    let data = {
      booking_score: respData?.booking_score,
      visit_score: respData?.visit_score,
      referal_score: respData?.referal_score,
      lead_add_score: respData?.lead_add_score,
      lead_convert: respData?.lead_convert,
      sell_score: respData?.sell_score,
    };
    setFormData(JSON.parse(JSON.stringify(data)));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUpdateInfo = async () => {
    setLoading(true);
    const payload = {
      booking_score: formData.booking_score,
      visit_score: formData.visit_score,
      referal_score: formData.referal_score,
      lead_add_score: formData.lead_add_score,
      lead_convert: formData.lead_convert,
      sell_score: formData.sell_score,
    };
    const response = await apiPut(API_ENDPOINT_SETTINGS_SCORE_SYSTEM, payload);
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    console.log("score system payload", payload);
    setLoading(false);
  };
  return (
    <Form
      fields={[
        {
          name: ["sell_score"],
          value: formData.sell_score,
        },
        {
          name: ["booking_score"],
          value: formData.booking_score,
        },
        {
          name: ["visit_score"],
          value: formData.visit_score,
        },
        {
          name: ["referal_score"],
          value: formData.referal_score,
        },
        {
          name: ["lead_add_score"],
          value: formData.lead_add_score,
        },
        {
          name: ["lead_convert"],
          value: formData.lead_convert,
        },
      ]}
      onFinish={handleUpdateInfo}
    >
      <div className="FormGroup">
        <div className="FormElement" style={{ width: "24%" }}>
          <Form.Item
            name="sell_score"
            // initialValue={formData.saleType}
            required
          >
            <Input
              placeholder="Property Sell Score"
              onChange={handleInputChange}
              name="sell_score"
              defaultValue={formData.sell_score}
              background="white"
              borderColor="black"
              label="Property Sell Score"
            />
          </Form.Item>
        </div>
        <div className="FormElement" style={{ width: "24%" }}>
          <Form.Item
            name="booking_score"
            // initialValue={formData.saleType}
            required
          >
            <Input
              placeholder="Property Book Score"
              onChange={handleInputChange}
              name="booking_score"
              defaultValue={formData.booking_score}
              background="white"
              borderColor="black"
              label="Property Book Score"
            />
          </Form.Item>
        </div>

        <div className="FormElement" style={{ width: "24%" }}>
          <Form.Item
            name="visit_score"
            // initialValue={formData.saleType}
            required
          >
            <Input
              placeholder="Property Visit Score"
              onChange={handleInputChange}
              name="visit_score"
              defaultValue={formData.visit_score}
              background="white"
              borderColor="black"
              label="Property Visit Score"
            />
          </Form.Item>
        </div>
        <div className="FormElement" style={{ width: "24%" }}>
          <Form.Item
            name="referal_score"
            // initialValue={formData.saleType}
            required
          >
            <Input
              placeholder="Broker Refferal Score"
              onChange={handleInputChange}
              name="referal_score"
              defaultValue={formData.referal_score}
              background="white"
              borderColor="black"
              label="Broker Refferal Score"
            />
          </Form.Item>
        </div>
      </div>
      <div className="FormGroup">
        <div className="FormElement" style={{ width: "24%" }}>
          <Form.Item
            name="lead_add_score"
            // initialValue={formData.saleType}
            required
          >
            <Input
              placeholder="Lead Create Score"
              onChange={handleInputChange}
              name="lead_add_score"
              defaultValue={formData.lead_add_score}
              background="white"
              borderColor="black"
              label="Lead Create Score"
            />
          </Form.Item>
        </div>
        <div className="FormElement" style={{ width: "24%" }}>
          <Form.Item
            name="lead_convert"
            // initialValue={formData.saleType}
            required
          >
            <Input
              placeholder="Lead Convert Score"
              onChange={handleInputChange}
              name="lead_convert"
              defaultValue={formData.lead_convert}
              background="white"
              borderColor="black"
              label="Lead Convert Score"
            />
          </Form.Item>
        </div>
        <div className="FormElement" style={{ width: "24%" }}></div>
        <div className="FormElement" style={{ width: "24%" }}></div>
      </div>

      <div className="FormGroup" style={{ justifyContent: "flex-end" }}>
        <div className="FormElement">
          <Form.Item>
            <Button htmlType="submit" loading={isLoading}>
              Update
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(ScoreSystem);
