import React, { useEffect, useState } from "react";
import "./PropertyDetails.scss";
import { Gallery } from "react-grid-gallery";
import {
  ENUM_KEY_TITLES,
  GLOBAL_VIDEO_FORMATS,
} from "app/constants/global.constants";
import { Carousel, Image, Modal, Tag } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import { FormattedNumber } from "react-intl";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DummyAvatar from "assets/images/DummyAvatar.png";
import Button from "app/shared/Button";
import PhoneIcon from "@mui/icons-material/Phone";
import MapBox from "app/shared/MapBox";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import EscalatorOutlinedIcon from "@mui/icons-material/EscalatorOutlined";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import NaturePeopleOutlinedIcon from "@mui/icons-material/NaturePeopleOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  API_ENDPOINT_PROJECT_PAYMENT_PLAN_INFO,
  API_ENDPOINT_PROPERTY_FETCH_IMAGES,
  API_ENDPOINT_PROPERTY_INFO,
} from "../../property.constants";
import {
  formatIndianAmount,
  formatIndianNumber,
  parseURL,
} from "app/utils/helpers/common.helper";
import { apiGet } from "app/services/apiService";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "app/shared/Loader";
import { API_ENDPOINT_USER_INFO } from "app/scenes/brokers/brokers.constants";
import TableView from "app/shared/TableView";

export default function PropertyDetails(props) {
  const [isLoading, setLoading] = useState(false);
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [images, setImages] = useState([]);
  const [agentInfo, setAgentInfo] = useState(null);
  const [showFullImage, setShowFullImage] = useState(false);
  const [customPlanInfo, setCustomPlanInfo] = useState(null);
  const [showModalFor, setShowModalFor] = useState("");
  let { propertyId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!propertyId) return;
    fetchPropertyInfo();
  }, [propertyId]);

  const fetchImages = async () => {
    setLoading(true);

    const response = await apiGet(
      parseURL(API_ENDPOINT_PROPERTY_FETCH_IMAGES, {
        propertyId: propertyId,
      })
    );
    if (response.status && response.data.length > 0) {
      setImages(response.data);
    } else {
      setImages([
        {
          url: "https://images.ctfassets.net/bed00l8ra6lf/5gauKvJAKwG7FJQtEVz7vJ/a57c984e42f71fa8f216eca14f77cd84/Before_you_start.svg",
        },
      ]);
    }
    setLoading(false);
  };

  const fetchCustomPlanInfo = async (projectId, planId) => {
    const response = await apiGet(
      parseURL(API_ENDPOINT_PROJECT_PAYMENT_PLAN_INFO, {
        projectId: projectId,
        planId: planId,
      })
    );
    if (response.status) {
      setCustomPlanInfo(response.data);
    }
  };

  const fetchAgentInfo = async (agentId) => {
    const response = await apiGet(API_ENDPOINT_USER_INFO + agentId);
    if (response.status) {
      setAgentInfo(response.data);
    }
  };

  const fetchPropertyInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_PROPERTY_INFO + propertyId);
    if (response.status) {
      setPropertyInfo(response.data);
      fetchImages();
      if (response.data && response.data.property_agents.length > 0) {
        fetchAgentInfo(response.data.property_agents[0].agentId);
      }
      if (
        response.data &&
        response.data.property_payment_plan?.custom_plan_id
      ) {
        fetchCustomPlanInfo(
          response.data.property_project?.id,
          response.data.property_payment_plan?.custom_plan_id
        );
      }
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
    setLoading(false);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div>
        <ArrowBackIcon
          style={{ cursor: "pointer", marginBottom: 24, fontSize: 22 }}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="PropertyDetails">
        <div className="DetailColumnOne">
          <div className="PropertyInfoContainer">
            <div className="PropertyImages">
              <div className="CarasoulContainer">
                <Carousel autoplay>
                  {images.map((image, key) => {
                    return (
                      <div key={key}>
                        {GLOBAL_VIDEO_FORMATS.includes(
                          image.url.substring(image.url.lastIndexOf(".") + 1)
                        ) ? (
                          <video
                            id="myVideo"
                            width="100%"
                            height="260"
                            autoplay
                            muted
                            controls="controls"
                          >
                            <source src={image.url} type="video/mp4" />
                          </video>
                        ) : (
                          <img
                            src={image.url}
                            alt="propertyImage"
                            style={{
                              height: 260,
                              width: "100%",
                              objectFit: "cover",
                              borderRadius: 16,
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              <div
                className="MoreImages"
                onClick={() => setShowFullImage(true)}
              >
                <div className="PropertyImageLast">
                  <img
                    src={
                      (images && images[2]?.url) || (images && images[0]?.url)
                    }
                    alt="propertyImage"
                  />
                </div>
                <div className="PropertyImageLast">
                  <img
                    src={
                      (images && images[1]?.url) || (images && images[0]?.url)
                    }
                    alt="propertyImage"
                  />
                  <div className="imageCount">
                    <Typography variant="xs">{images.length} photos</Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="BasicPropertyInfo">
              <Typography variant="h6" style={{ marginTop: 14 }}>
                <b style={{ fontWeight: 700, color: colorPicker("red") }}>
                  {" "}
                  ₹
                  {formatIndianAmount((propertyInfo && propertyInfo.cost) || 0)}
                  {/* <FormattedNumber
                    value={propertyInfo && propertyInfo.cost}
                    style="currency"
                    currency="INR"
                    currencyDisplay="symbol"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                    format={formatIndianNumber}
                  /> */}
                </b>
              </Typography>
              <Typography
                variant="body"
                color="gray-dark"
                style={{ marginTop: 8 }}
              >
                {propertyInfo && propertyInfo.name}
                {/* 2BHK flat in koramangala */}
              </Typography>

              <div className="Amentities">
                {propertyInfo &&
                (propertyInfo.property_type?.name === "Flat" ||
                  propertyInfo.property_type?.name === "House/Villa") ? (
                  <>
                    <div className="Amentity">
                      <BedOutlinedIcon
                        fontSize={"medium"}
                        style={{ color: "#000929" }}
                      />
                      <Typography
                        variant="caption"
                        style={{ marginLeft: 4 }}
                        color="black"
                      >
                        {propertyInfo && propertyInfo.bedroom}
                      </Typography>
                    </div>

                    <div className="Amentity">
                      <BathtubOutlinedIcon
                        fontSize={"medium"}
                        style={{ color: "#000929" }}
                      />
                      <Typography
                        variant="caption"
                        style={{ marginLeft: 4 }}
                        color="black"
                      >
                        {propertyInfo && propertyInfo.bathroom}
                      </Typography>
                    </div>
                  </>
                ) : null}

                <div className="Amentity">
                  <AspectRatioOutlinedIcon
                    fontSize={"medium"}
                    style={{ color: "#000929" }}
                  />
                  <Typography
                    variant="caption"
                    style={{ marginLeft: 4 }}
                    color="black"
                  >
                    {propertyInfo && propertyInfo.size}{" "}
                    {propertyInfo?.size_unit}
                    {/* 2334 sqft */}
                  </Typography>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 12 }}
              >
                <LocationOnIcon
                  fontSize={"small"}
                  style={{ color: "#000929" }}
                />
                <Typography variant="caption" color="gray-dark">
                  {propertyInfo &&
                    propertyInfo.property_project?.property_project_address
                      ?.address_one}{" "}
                  {propertyInfo &&
                    propertyInfo.property_project?.property_project_address
                      ?.address_two}{" "}
                  {propertyInfo &&
                    propertyInfo.property_project?.property_project_address
                      ?.city}{" "}
                  {propertyInfo &&
                    propertyInfo.property_project?.property_project_address
                      ?.state}{" "}
                  {propertyInfo &&
                    propertyInfo.property_project?.property_project_address
                      ?.zip}
                  {/* Near Petrol Pum , Koramangala 24d block, 456044,Bangalore */}
                </Typography>
              </div>
              <Tag color="#108ee9" style={{ marginTop: 12 }}>
                {propertyInfo && propertyInfo?.property_code}
              </Tag>

              <Tag
                color="green"
                style={{ marginTop: 12 }}
                onClick={() => {
                  setShowModalFor("UNIT_LIST");
                  setShowFullImage(true);
                }}
              >
                Total Units -{" "}
                {propertyInfo && propertyInfo?.property_units_lists?.length}
              </Tag>
            </div>
          </div>
          <div className="MoreInfoContainer">
            <Typography variant="h6">More Info</Typography>
            <div className="InfoList">
              <div className="InfoListItem">
                <div className="InfoListItemTitle">
                  <Typography color="gray-dark">Booking Amount</Typography>
                </div>
                <Typography color={colorPicker("red")}>
                  <Typography style={{ marginTop: 14 }}>
                    <b style={{ fontWeight: 700, color: colorPicker("red") }}>
                      <FormattedNumber
                        value={propertyInfo && propertyInfo.booking_amount}
                        style="currency"
                        currency={"INR"}
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </b>
                  </Typography>
                </Typography>
              </div>

              <div className="InfoListItem">
                <div className="InfoListItemTitle">
                  <Typography color="gray-dark">Road Sizes</Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Tag color="blue">
                    East - {propertyInfo?.property_plot_road?.east_road} ft
                  </Tag>

                  <Tag color="blue">
                    West - {propertyInfo?.property_plot_road?.west_road} ft
                  </Tag>

                  <Tag color="blue">
                    North - {propertyInfo?.property_plot_road?.north_road} ft
                  </Tag>

                  <Tag color="blue">
                    South - {propertyInfo?.property_plot_road?.south_road} ft
                  </Tag>
                </div>
              </div>

              <div className="InfoListItem">
                <div className="InfoListItemTitle">
                  <Typography color="gray-dark">Furnishing</Typography>
                </div>
                <Typography>
                  {ENUM_KEY_TITLES[propertyInfo && propertyInfo.furnish_status]}
                </Typography>
              </div>

              {propertyInfo &&
              propertyInfo.transaction_type !== "NEW_PROPERTY" ? (
                <div className="InfoListItem">
                  <div className="InfoListItemTitle">
                    <Typography color="gray-dark">Age Of Property</Typography>
                  </div>
                  <Typography>
                    {propertyInfo && propertyInfo.property_age} Yr
                  </Typography>
                </div>
              ) : null}

              <div className="InfoListItem">
                <div className="InfoListItemTitle">
                  <Typography color="gray-dark">Transaction Type</Typography>
                </div>
                <Typography>
                  {
                    ENUM_KEY_TITLES[
                      propertyInfo && propertyInfo.transaction_type
                    ]
                  }
                </Typography>
              </div>

              <div className="InfoListItem">
                <div className="InfoListItemTitle">
                  <Typography color="gray-dark">Facing</Typography>
                </div>
                <Typography> {propertyInfo && propertyInfo.facing}</Typography>
              </div>
              {propertyInfo &&
              propertyInfo.property_payment_plan?.emi &&
              !propertyInfo.property_payment_plan?.custom_plan_id ? (
                <div className="InfoListItem">
                  <div className="InfoListItemTitle">
                    <Typography color="gray-dark">Loan Offered</Typography>
                  </div>
                  <Typography color={colorPicker("red")}>
                    <Typography>
                      EMI starts at :{" "}
                      <b style={{ fontWeight: 700, color: colorPicker("red") }}>
                        <FormattedNumber
                          value={propertyInfo.property_payment_plan?.emi}
                          style="currency"
                          currency={"INR"}
                          minimumFractionDigits={0}
                          maximumFractionDigits={0}
                        />
                      </b>
                    </Typography>
                  </Typography>
                </div>
              ) : null}

              <div className="InfoListItem">
                <div className="InfoListItemTitle">
                  <Typography color="gray-dark">Property Developer</Typography>
                </div>
                <Typography>
                  <b style={{ textDecoration: "underline" }}>
                    {propertyInfo &&
                      propertyInfo?.property_project?.property_developer?.name}
                  </b>
                </Typography>
              </div>

              <div className="InfoListItem">
                <div className="InfoListItemTitle">
                  <Typography color="gray-dark">Property Project</Typography>
                </div>
                <Typography>
                  {" "}
                  <b style={{ textDecoration: "underline" }}>
                    {" "}
                    {propertyInfo && propertyInfo?.property_project?.name}
                  </b>
                </Typography>
              </div>
            </div>
          </div>
          <div className="AmentityContainer">
            <Typography variant="h6">Payment Info</Typography>
            <div className="AmentitiyList" style={{ width: "100%" }}>
              {propertyInfo?.property_payment_plan?.plan_type === "PAYMENT" &&
              customPlanInfo &&
              customPlanInfo.property_payment_schemes_installments ? (
                <div style={{ width: "100%" }}>
                  <Typography color="gray-dark" style={{ marginBottom: 8 }}>
                    Customize payment plan offered by our project developer
                  </Typography>
                  <TableView
                    data={
                      customPlanInfo.property_payment_schemes_installments || []
                    }
                    column={[
                      {
                        title: "Installment",
                        index: "name",
                      },

                      {
                        title: "Percentage (%)",
                        index: "percentage",
                      },
                      {
                        title: "On Day",
                        index: "day",
                      },
                    ]}
                    style={{ width: "100%" }}
                  />
                </div>
              ) : (
                <div className="InfoListItem">
                  <div className="InfoListItemTitle">
                    <Typography color="gray-dark">
                      Loan Offered by our lending partners
                    </Typography>
                  </div>

                  <div
                    className="emiBreakdown"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                    }}
                  >
                    <div
                      className="BreakdownCard"
                      style={{
                        width: 200,
                        backgroundColor: "black",
                        padding: 16,
                        borderRadius: 4,
                        marginRight: 44,
                      }}
                    >
                      <Typography color="white" style={{ marginBottom: 12 }}>
                        EMI
                      </Typography>
                      <Typography color="white" variant="h5">
                        {" "}
                        <FormattedNumber
                          value={propertyInfo?.property_payment_plan?.emi}
                          style="currency"
                          currency={"INR"}
                          minimumFractionDigits={0}
                          maximumFractionDigits={0}
                        />
                      </Typography>
                    </div>
                    <div
                      className="BreakdownCard"
                      style={{
                        width: 200,
                        backgroundColor: "black",
                        padding: 16,
                        borderRadius: 4,
                        marginRight: 44,
                      }}
                    >
                      <Typography color="white" style={{ marginBottom: 12 }}>
                        Downpayment
                      </Typography>
                      <Typography color="white" variant="h5">
                        {" "}
                        <FormattedNumber
                          value={
                            propertyInfo?.property_payment_plan?.downpayment
                          }
                          style="currency"
                          currency={"INR"}
                          minimumFractionDigits={0}
                          maximumFractionDigits={0}
                        />
                      </Typography>
                    </div>

                    <div
                      className="BreakdownCard"
                      style={{
                        width: 200,
                        backgroundColor: "black",
                        padding: 16,
                        borderRadius: 4,
                        marginRight: 44,
                      }}
                    >
                      <Typography color="white" style={{ marginBottom: 12 }}>
                        Interest (%)
                      </Typography>
                      <Typography color="white" variant="h5">
                        {propertyInfo?.property_payment_plan?.interest}
                      </Typography>
                    </div>
                    <div
                      className="BreakdownCard"
                      style={{
                        width: 200,
                        backgroundColor: "black",
                        padding: 16,
                        borderRadius: 4,
                        marginRight: 44,
                      }}
                    >
                      <Typography color="white" style={{ marginBottom: 12 }}>
                        Tenure (Yr)
                      </Typography>
                      <Typography color="white" variant="h5">
                        {propertyInfo?.property_payment_plan?.plan_duration}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="AmentityContainer">
            <Typography variant="h6">Amentities</Typography>
            <div className="AmentitiyList">
              {propertyInfo &&
                propertyInfo.property_project?.project_amentities &&
                propertyInfo.property_project?.project_amentities.map(
                  (amentitiy, key) => {
                    return (
                      <div className="Amentity" key={key}>
                        <LightbulbOutlinedIcon
                          style={{
                            color: colorPicker("red"),
                            fontSize: 20,
                            marginRight: 6,
                          }}
                        />
                        <Typography variant="xs" color="gray-dark">
                          {amentitiy?.amentity?.name}
                        </Typography>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
        <div className="DetailColumnTwo">
          <div className="ReferDetail">
            <Typography variant="body" style={{ marginBottom: 4 }}>
              Agent Details
            </Typography>
            <div className="UserInfo">
              <Image
                width={78}
                style={{ borderRadius: 12 }}
                src={
                  (agentInfo && agentInfo.profileInfo?.user_profile?.avatar) ||
                  DummyAvatar
                }
              />
              <div className="ReferalInfo">
                <div className="nameInfo">
                  <Typography
                    style={{ width: "50%" }}
                    variant="xs"
                    color="gray-dark"
                  >
                    Name :
                  </Typography>
                  <Typography variant="xs" color="gray-dark">
                    {agentInfo && agentInfo.profileInfo.user_profile.first_name}{" "}
                    {agentInfo && agentInfo.profileInfo.user_profile.last_name}
                  </Typography>
                </div>
                <div className="nameInfo">
                  <Typography
                    style={{ width: "50%" }}
                    variant="xs"
                    color="gray-dark"
                  >
                    Email :
                  </Typography>
                  <Typography variant="xs" color="gray-dark">
                    {agentInfo && agentInfo.profileInfo.email}{" "}
                    {/* fsdfFdsf2@f.com */}
                  </Typography>
                </div>
                <div className="nameInfo">
                  <Typography
                    style={{ width: "50%" }}
                    variant="xs"
                    color="gray-dark"
                  >
                    Phone :
                  </Typography>
                  <Typography variant="xs" color="gray-dark">
                    {agentInfo && agentInfo.profileInfo.user_profile.mobile}{" "}
                    {/* +91004499585 */}
                  </Typography>
                </div>
                <div className="nameInfo">
                  <Typography
                    style={{ width: "50%" }}
                    variant="xs"
                    color="gray-dark"
                  >
                    Referal Code :
                  </Typography>
                  <Typography variant="xs" color="gray-dark">
                    {agentInfo && agentInfo.profileInfo.user_profile.member_id}
                    {/* FDSF@@ */}
                  </Typography>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 28 }}>
              <Button
                type="primary"
                size="medium"
                style={{ width: "100%" }}
                icon={<PhoneIcon style={{ fontSize: 16 }} />}
                // onClick={() => setSceneTab(1)}
              >
                Contact Agent
              </Button>
            </div>
          </div>
          <div className="MapBoxContainer">
            <MapBox
              defaultValue={{
                latitude:
                  (propertyInfo &&
                    propertyInfo?.property_address?.latlong &&
                    propertyInfo &&
                    parseFloat(
                      propertyInfo?.property_address?.latlong.split(",")[0]
                    )) ||
                  0,
                longitude:
                  (propertyInfo &&
                    propertyInfo?.property_address?.latlong &&
                    propertyInfo &&
                    parseFloat(
                      propertyInfo?.property_address?.latlong.split(",")[1]
                    )) ||
                  0,
              }}
            />
          </div>

          {propertyInfo &&
            propertyInfo.property_documents &&
            propertyInfo.property_documents.map((document, key) => {
              return (
                <div
                  className="PropertyDocumentCard"
                  onClick={() => window.open(document.link, "_blank")}
                  key={key}
                >
                  <PaymentOutlinedIcon style={{ color: colorPicker("red") }} />
                  <Typography style={{ marginLeft: 4 }} color="red">
                    {document.name || document.type.split("_").join(" ")}
                  </Typography>
                </div>
              );
            })}
        </div>
        <Modal
          title={
            showModalFor === "UNIT_LIST"
              ? "Unit Availability"
              : "Property Images"
          }
          centered
          open={showFullImage}
          onCancel={() => setShowFullImage(false)}
          width={1000}
          footer={false}
        >
          {showModalFor !== "UNIT_LIST" ? (
            <div
              className="PropertyImageList"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {images.map((image, key) => {
                <Image key={key} width={200} src={image.url} />;
              })}

              {images.map((image, key) => {
                return (
                  <div key={key} style={{ marginRight: 6, marginBottom: 6 }}>
                    {GLOBAL_VIDEO_FORMATS.includes(
                      image.url.substring(image.url.lastIndexOf(".") + 1)
                    ) ? (
                      <video
                        id="myVideo"
                        width="100%"
                        height="260"
                        autoplay
                        muted
                        controls="controls"
                      >
                        <source src={image.url} type="video/mp4" />
                      </video>
                    ) : (
                      <Image key={key} width={200} src={image.url} />
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              {propertyInfo &&
              Array.isArray(propertyInfo.property_units_lists) &&
              propertyInfo.property_units_lists.length > 0
                ? propertyInfo.property_units_lists.map((item, key) => (
                    <Tag
                      key={key}
                      color={
                        item.status === "AVAILABLE"
                          ? "green"
                          : item.status === "HOLD"
                          ? "yellow"
                          : "red"
                      }
                    >
                      {item.unit_no}
                    </Tag>
                  ))
                : []}
            </div>
          )}
        </Modal>
      </div>
    </>
  );
}
