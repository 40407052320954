import brokersRoutes from "app/scenes/brokers/brokers.routes";
import Dashboard from "app/scenes/dashboard";
import employeeRoutes from "app/scenes/employee/employee.routes";
import leadsRoutes from "app/scenes/leads/leads.routes";
import leavesRoutes from "app/scenes/leaves/leaves.routes";
import promotersRoutes from "app/scenes/promoters/promoters.routes";
import propertyRoutes from "app/scenes/property/property.routes";
import Settings from "app/scenes/settings";
import ProtectedRoute from "app/utils/ProtectedRoute";
import MasterLayout from "./";
import announcementRoutes from "app/scenes/announcement/announcement.routes";
import offersRoutes from "app/scenes/offers/offers.routes";
import reportsRoutes from "app/scenes/reports/reports.routes";
import customersRoutes from "app/scenes/customers/customers.routes";

export default {
  path: "",
  element: (
    <ProtectedRoute>
      <MasterLayout />
    </ProtectedRoute>
  ),
  strict: true,
  children: [
    {
      path: "/",
      element: <Dashboard />,
      exact: true,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      exact: true,
    },
    {
      path: "/settings",
      element: <Settings />,
      exact: true,
    },
    propertyRoutes,
    brokersRoutes,
    promotersRoutes,
    employeeRoutes,
    customersRoutes,
    leadsRoutes,
    leavesRoutes,
    announcementRoutes,
    offersRoutes,
    reportsRoutes,
  ],
};
