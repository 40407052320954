import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import React, { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Avatar, Button, Input, Popconfirm, Space, Table, Tag } from "antd";
import { get } from "lodash";
import Loader from "../Loader";
import { colorPicker } from "app/utils/helpers/color.helper";
import { ACCOUNT_STATUS_MAP_COLOR } from "app/constants/global.constants";
import moment from "moment";
import { FormattedNumber } from "react-intl";
import Typography from "../Typography";
import { useNavigate } from "react-router";
import { formatIndianAmount } from "app/utils/helpers/common.helper";
const dummyAvatarBackground = [
  "#27ae60",
  "#e67e22",
  "#e74c3c",
  "#2c3e50",
  "#16a085",
];
const TableView = (props) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const navigate = useNavigate();
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log("table changing");
    // setTableParams({
    //   pagination,
    //   filters,
    //   ...sorter,
    // });
    // `dataSource` is useless since `pageSize` changed
    // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
    //   setData([]);
    // }
  };
  const getColumnSearchProps = (dateIndexItem) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dateIndexItem.title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dateIndexItem.index)
          }
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dateIndexItem.index)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      (get(record, dateIndexItem.index, "NA") || "NA")
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dateIndexItem.index ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={get(text, dateIndexItem.index, "NA")}
        />
      ) : (
        get(text, dateIndexItem.index, "NA")
      ),
  });

  const handleCustomRender = (item, colItem, fullItemObj) => {
    if (colItem.renderTag) {
      return (
        <Tag
          color={
            (colItem.tagColorMap || ACCOUNT_STATUS_MAP_COLOR)[
              get(item, colItem.index, "NA")
            ] || "blue"
          }
          style={{ cursor: "pointer" }}
          onClick={() =>
            colItem.url_redirect_key
              ? navigate("/leads/" + get(item, colItem.url_redirect_key, "NA"))
              : null
          }
        >
          {get(item, colItem.index, "NA")?.replaceAll("_", " ")}
        </Tag>
      );
    } else if (colItem.customRender) {
      return colItem.customRender({ ...item, ...props });
    } else if (colItem.avatar) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            size={24}
            src={get(item, colItem.avatar, "")}
            icon={
              !get(item, colItem.avatar, "") &&
              (colItem.dummyAvatar ? colItem.dummyAvatar : <UserOutlined />)
            }
            style={{
              marginRight: 6,
              backgroundColor:
                dummyAvatarBackground[Math.floor(Math.random() * 5 + 1) - 1],
            }}
          />
          {get(item, colItem.index, "NA")}
        </div>
      );
    } else if (colItem.renderAction) {
      let actions = [];
      colItem.actions.forEach((action) => {
        if (
          action.showOn &&
          !action.showOn({
            ...item,
            hasAccess: props.hasAccess && props.hasAccess(action.accessKey),
          })
        )
          return;
        actions.push(
          !action.popup ? (
            <div
              style={{
                width: 22,
                height: 22,
                borderRadius: "50%",
                backgroundColor: action.color,
                marginRight: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() =>
                colItem.handleAction({
                  ...props,
                  eventType: action.event,
                  rowData: item,
                })
              }
            >
              {action.icon}
            </div>
          ) : (
            <Popconfirm
              title={action.popupTitle}
              // description="Are you sure to delete this task?"
              onConfirm={() =>
                colItem.handleAction({
                  ...props,
                  eventType: action.event,
                  rowData: item,
                })
              }
              onCancel={null}
              okText="Yes"
              cancelText="No"
            >
              <div
                style={{
                  width: 22,
                  height: 22,
                  borderRadius: "50%",
                  backgroundColor: action.color,
                  marginRight: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                {action.icon}
              </div>
            </Popconfirm>
          )
        );
      });

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {actions}
        </div>
      );
    } else if (colItem.amount) {
      // return (
      //   <FormattedNumber
      //     value={get(item, colItem.index, 0)}
      //     style="currency"
      //     currency={"INR"}
      //     minimumFractionDigits={0}
      //     maximumFractionDigits={0}
      //   />
      // );
      return "₹" + formatIndianAmount(get(item, colItem.index, 0) || 0);
    } else if (colItem.child) {
      return colItem.child({
        ...item,
        handleSwitch: (userId, status) =>
          props.handleAction({
            ...props,
            eventType: "toggle",
            rowData: { id: userId, NEW_STATUS: status },
          }),
      });
    } else {
      return colItem.date
        ? moment(get(item, colItem.index)).format("DD/MM/YYYY")
        : get(item, colItem.index, "NA");
    }
  };
  const getColumnReady = () => {
    let newColumn = [];
    props.column.forEach((item) => {
      let newColData = {
        ...item,
      };
      if (!item.render)
        newColData.render = (data, fullObj) =>
          handleCustomRender(data, item, fullObj);

      if (item.search)
        newColData = {
          ...newColData,
          ...getColumnSearchProps(item),
          render: (data) => handleCustomRender(data, item),
        };
      newColumn.push(newColData);
    });
    return newColumn;
  };
  return (
    <Table
      columns={getColumnReady()}
      dataSource={props.data}
      style={props.style}
      loading={props.isLoading}
      onChange={handleTableChange}
      pagination={{ pageSize: 8 }}
    />
  );
};
export default TableView;
