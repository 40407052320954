import Typography from "app/shared/Typography";
import HomeIcon from "assets/images/home.svg";
import ComissionIcon from "assets/images/comission.svg";
import VisitIcon from "assets/images/visit.svg";

import "./BrokerProfile.scss";
import { Image, Tag } from "antd";
import { useEffect, useState } from "react";
import { apiGet } from "app/services/apiService";
import { API_ENDPOINT_USER_INFO } from "app/scenes/brokers/brokers.constants";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Loader from "app/shared/Loader";
import moment from "moment";
const imageType = {
  HOME: HomeIcon,
  VISIT: VisitIcon,
  COMISSION: ComissionIcon,
};
export default function BrokerProfile(props) {
  return props.isLoading ? (
    <Loader />
  ) : (
    <div className="BrokerProfile">
      <div className="TopHeader">
        <Typography variant="h5">Profile Details</Typography>
      </div>
      <div className="UserDetails">
        <div className="UserInfoGroup">
          <Typography>Member Code</Typography>
          <Tag color="blue">
            {props.data && props.data.profileInfo?.user_profile?.member_id}
          </Tag>
        </div>

        <div className="UserInfoGroup">
          <Typography>Name</Typography>
          <Typography color="gray-dark">
            {props.data && props.data.profileInfo?.user_profile?.first_name}{" "}
            {props.data && props.data.profileInfo?.user_profile?.last_name}
          </Typography>
        </div>

        <div className="UserInfoGroup">
          <Typography>Create Date</Typography>
          <Typography color="gray-dark">
            {moment(props.data && props.data.profileInfo?.createdAt).format(
              "DD/MM/YYYY"
            )}
          </Typography>
        </div>
        <div className="UserInfoGroup">
          <Typography>Approved Date</Typography>
          <Typography color="gray-dark">
            {moment(props.data && props.data.profileInfo?.createdAt).format(
              "DD/MM/YYYY"
            )}
          </Typography>
        </div>
        {/* <div className="UserInfoGroup">
          <Typography>Approved By</Typography>
          <Typography color="gray-dark">Ally Craister</Typography>
        </div> */}
        <div className="UserInfoGroup">
          <Typography>Name</Typography>
          <Typography color="gray-dark">
            {props.data && props.data.profileInfo?.email}
          </Typography>
        </div>

        <div className="UserInfoGroup">
          <Typography>Mobile</Typography>
          <Typography color="gray-dark">
            {props.data && props.data.profileInfo?.user_profile?.mobile}
          </Typography>
        </div>
        <div className="UserInfoGroup">
          <Typography>Gender</Typography>
          <Typography color="gray-dark">
            {(props.data && props.data.profileInfo?.user_profile?.gender) ||
              "NA"}
          </Typography>
        </div>
        <div className="UserInfoGroup">
          <Typography>City</Typography>
          <Typography color="gray-dark">
            {(props.data && props.data.profileInfo?.user_profile?.city) || "NA"}
          </Typography>
        </div>

        <div className="UserInfoGroup">
          <Typography>Date of Birth</Typography>
          <Typography color="gray-dark">
            {(props.data &&
              props.data.profileInfo?.user_profile?.dob &&
              moment(props.data.profileInfo?.user_profile?.dob).format(
                "DD/MM/YYYY"
              )) ||
              "NA"}
          </Typography>
        </div>

        <div className="UserInfoGroup">
          <Typography>Company</Typography>
          <Typography color="gray-dark">
            {(props.data && props.data.profileInfo?.user_profile?.company) ||
              "NA"}
          </Typography>
        </div>

        {props.data &&
          props.data.profileInfo?.user_documents.map((document, key) => {
            return (
              <div className="UserInfoGroup" key={"number" + key}>
                <Typography>{document.type} Number</Typography>
                <Typography color="gray-dark">
                  {document.document_no || "NA"}
                </Typography>
              </div>
            );
          })}

        {props.data &&
          props.data.profileInfo?.user_documents.map((document, key) => {
            return (
              <div className="UserInfoGroup" key={key}>
                <Typography>{document.type} Photo</Typography>
                <Image
                  width={154}
                  height={78}
                  style={{ marginTop: 4 }}
                  src={document.url_image_front}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
