import "./App.css";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import Entry from "Entry";
import { Provider, useDispatch } from "react-redux";
import configureStore from "store";
import Toast from "app/shared/Toast/Toast";
import { IntlProvider } from "react-intl";
import { FeatureFlagsProvider } from "app/utils/FeatureFlag/FeatrueFlag";
import locale from "antd/locale/en_US";
import { useEffect, useState } from "react";
import { SAVE_USER_ACCESS_INFO } from "app/scenes/auth/redux/auth.action";
import { API_ENDPOINT_DASHBOARD_HUDDLE } from "app/scenes/dashboard/dashboard.constants";
import { apiGet } from "app/services/apiService";
import Loader from "app/shared/Loader";

function App() {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    fetchDependencies();
  }, []);

  const fetchDependencies = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_DASHBOARD_HUDDLE);
    if (response.status) {
      dispatch({
        type: SAVE_USER_ACCESS_INFO,
        payload: response.data.feature_access,
      });
    } else {
      dispatch({
        type: SAVE_USER_ACCESS_INFO,
        payload: [],
      });
    }
    setLoading(false);
  };

  // console.log("routeconfig", routeConfig);
  return isLoading ? (
    <Loader />
  ) : (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#000929",
          colorSecondary: "#E60023",
          fontFamily: "Futura",
        },
      }}
      locale={locale}
    >
      <IntlProvider locale="en" defaultLocale="en">
        <Router>
          <Entry />
        </Router>
        <Toast />
      </IntlProvider>
      ,
    </ConfigProvider>
  );
}

export default App;
