import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, message, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "./AddAmentity.scss";
import Papa from "papaparse";
import { apiGet, apiPost, apiPut } from "app/services/apiService";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import {
  API_ENDPOINT_GLOBAL_ADD_AMENTITY,
  API_ENDPOINT_GLOBAL_ADD_PAYMENT_PLAN,
  API_ENDPOINT_GLOBAL_ADD_PROPERTY_TYPES,
  API_ENDPOINT_GLOBAL_UPDATE_PAYMENT_PLAN,
} from "app/scenes/property/property.constants";
import SearchSelector from "app/shared/SearchSelector";
import { parseURL } from "app/utils/helpers/common.helper";
export default function EditPaymentPlan(props) {
  const [planName, setPlanName] = useState("");
  const [project, setProject] = useState("");
  const [projectName, setProjectName] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [plansList, setPlansList] = useState(
    props.editMode
      ? []
      : [
          {
            id: 1,
            order: 1,
            name: "",
            amount: null,
            percentage: null,
            day: "",
          },
        ]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.editMode) {
      setProjectName("");
      setPlanName("");
      setPlansList([
        {
          id: 1,
          order: 1,
          name: "",
          amount: null,
          percentage: null,
          day: "",
        },
      ]);
    }
  }, [props.editMode]);

  const addRowInvite = () => {
    let sorted = plansList.sort((a, b) => b.order - a.order);

    const currentId = sorted[0].order;
    let newArrayData = [
      ...plansList,
      {
        name: "",
        amount: " ",
        percentage: " ",
        day: " ",
        id: currentId + 1,
        order: currentId + 1,
      },
    ];
    setPlansList(newArrayData.sort((a, b) => a.order - b.order));
  };

  useEffect(() => {
    if (props.editMode && props.planData) {
      setPlanName(props.planData?.name);
      setProject(props.planData?.projectId);
      if (props.planData.property_project !== null)
        setProjectName(
          "#PROJ-" +
            props.planData?.property_project?.project_unique_code +
            " - " +
            props.planData?.property_project?.name
        );
      let allplans = [];
      props.planData &&
        props.planData.property_payment_schemes_installments.forEach((plan) => {
          allplans.push({
            id: plan.order,
            amount: plan.amount,
            percentage: plan.percentage,
            day: plan.day,
            order: plan.order,
            name: plan.name,
          });
        });
      console.log("planstype", allplans);
      if (allplans.length === 0) {
        allplans.push({
          id: 1,
          order: 1,
          name: "",
          amount: null,
          percentage: null,
          day: "",
        });
      }
      setPlansList(allplans);
    }
  }, [props.planData]);

  const removeRowInvite = (itemId) => {
    setPlansList(plansList.filter((item) => item.id !== itemId));
  };

  const handleAddAmentity = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_GLOBAL_ADD_PAYMENT_PLAN, {
      name: planName,
      projectId: project,
      plans: plansList,
    });
    if (!project) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Please add project", type: 0 },
      });
      setLoading(false);
    }

    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const handleUpdateAmentity = async () => {
    console.log("payment plan info", {
      name: planName,
      projectId: project,
      plans: plansList,
    });
    setLoading(true);
    if (!project) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Please add project", type: 0 },
      });
      setLoading(false);
    }

    const response = await apiPut(
      parseURL(API_ENDPOINT_GLOBAL_UPDATE_PAYMENT_PLAN, {
        projectId: props.planData?.projectId,
        planId: props.planData?.id,
      }),
      {
        name: planName,
        projectId: project,
        plans: plansList,
      }
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const handleInput = (rowId, key, data) => {
    let prevData = JSON.parse(JSON.stringify(plansList));
    prevData[rowId][key] = data;
    setPlansList(prevData);
  };
  console.log("planList", plansList);
  return (
    <div className="PropertyTypeAdd">
      <Form
        name="basic"
        // initialValues={{ remember: true, plansList }}
        onFinish={props.editMode ? handleUpdateAmentity : handleAddAmentity}
        // onFinishFailed={onFinishFailed}
        fields={[
          {
            name: "planName",
            value: planName,
          },
          {
            name: "project",
            value: projectName,
          },
        ]}
        autoComplete="off"
      >
        <div className="formContainer">
          <div className="form-row" style={{ justifyContent: "flex-start" }}>
            <div
              className="input-elements"
              style={{ marginRight: 24, width: "30%" }}
            >
              <Form.Item
                name={"planName"}
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                initialValue={planName}
                style={{ marginBottom: 0 }}
              >
                <Input
                  placeholder={"Plan Name"}
                  name={"planName"}
                  onChange={(e) => setPlanName(e.target.value)}
                  defaultValue={planName}
                />
              </Form.Item>
            </div>

            <div className="input-elements" style={{ width: "35%" }}>
              <Form.Item
                name={"project"}
                style={{ marginBottom: 0, width: "100%" }}
              >
                <SearchSelector
                  placeholder="Project Id (#PROJ-12)"
                  serviceType="PROJECT"
                  valueKey="id"
                  labelKey="projectUniqueCode"
                  onChange={(value) => setProject(value)}
                  height={50}
                  defaultValue={projectName}
                />
              </Form.Item>
            </div>
          </div>
          <Typography style={{ marginBottom: 12 }} variant="caption">
            Installments Details
          </Typography>
          {plansList.map((amentity, key) => {
            return (
              <div className="form-row" key={key}>
                <div className="input-element">
                  <Form.Item
                    name={"name" + amentity.id}
                    rules={[
                      {
                        required: true,
                        message: "Please check your input",
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                    initialValue={amentity.name}
                  >
                    <Input
                      placeholder="Name"
                      name={"name" + amentity.id}
                      onChange={(e) => handleInput(key, "name", e.target.value)}
                      defaultValue={amentity.name}
                    />
                  </Form.Item>
                </div>
                <div className="input-element">
                  <Form.Item
                    name={"amount" + amentity.id}
                    style={{ marginBottom: 0 }}
                    initialValue={amentity.amount}
                  >
                    <Input
                      placeholder="Fixed Amount"
                      name={"amount" + amentity.id}
                      onChange={(e) =>
                        handleInput(key, "amount", e.target.value)
                      }
                      defaultValue={amentity.amount}
                    />
                  </Form.Item>
                </div>

                <div className="input-element">
                  <Form.Item
                    name={"percentage" + amentity.id}
                    style={{ marginBottom: 0 }}
                    initialValue={amentity.percentage}
                  >
                    <Input
                      placeholder="Percentage (eg 20%)"
                      name={"percentage" + amentity.id}
                      onChange={(e) =>
                        handleInput(key, "percentage", e.target.value)
                      }
                      defaultValue={amentity.percentage}
                    />
                  </Form.Item>
                </div>

                <div className="input-element">
                  <Form.Item
                    name={"day" + amentity.id}
                    rules={[
                      {
                        required: true,
                        message: "Please check your input",
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                    initialValue={amentity.day}
                  >
                    <Input
                      placeholder="Duration  (in Days)"
                      name={"percentage" + amentity.id}
                      onChange={(e) => handleInput(key, "day", e.target.value)}
                      defaultValue={amentity.day}
                    />
                  </Form.Item>
                </div>

                <PlusCircleOutlined onClick={addRowInvite} />
                <DeleteOutlined onClick={() => removeRowInvite(amentity.id)} />
              </div>
            );
          })}
        </div>
        {/* <Upload
          {...props}
          accept=".csv"
          beforeUpload={beforeUpload}
          customRequest={null}
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        >
          <Typography
            variant="xs"
            color="primary"
            style={{ cursor: "pointer" }}
          >
            Add Bulk Amentity
          </Typography>
        </Upload> */}

        <div className="action-buttons">
          <Form.Item style={{ width: 240 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
            >
              {props.editMode ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
