import React, { useEffect, useState } from "react";
import { Select } from "antd";

export default function SearchSelectV2(props) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    console.log("new options", props.staticData);
    if (props.staticData) setOptions(props.staticData);
  }, [props.staticData]);
  return (
    <Select
      showSearch
      style={{
        width: "100%",
        height: 48,
      }}
      placeholder={props.placeholder}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? "").includes(input)}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      options={options}
      defaultValue={props.defaultValue}
      onChange={(newValue) => {
        props.onChange && props.onChange(newValue);
      }}
    />
  );
}
