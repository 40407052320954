import { useRoutes } from "react-router-dom";

import { useDispatch } from "react-redux";
import RouteMap from "app/configs/RouteConfig";
import { useEffect, useState } from "react";
import { API_ENDPOINT_DASHBOARD_HUDDLE } from "app/scenes/dashboard/dashboard.constants";
import { apiGet } from "app/services/apiService";
import { SAVE_USER_ACCESS_INFO } from "app/scenes/auth/redux/auth.action";
import "theme/index.scss";

const Entry = () => {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const routes = useRoutes([...RouteMap]);
  return routes;
};

export default Entry;
