import moment from "moment";
import * as XLSX from "xlsx";
const _ = require("lodash");

export const parseURL = (string, obj) => {
  var s = string;
  for (var prop in obj) {
    s = s.replace(new RegExp("{{" + prop + "}}", "g"), obj[prop]);
  }
  return s;
};

export const formatIndianAmount = (number) => {
  const formatter = new Intl.NumberFormat("en-IN");
  return formatter.format(number);
};

export const exportToExcel = (data, columnData, name = "data") => {
  try {
    let preparedData = [];
    const objectMap = columnData.reduce((acc, column) => {
      acc[column.title] = column.index;
      return acc;
    }, {});

    console.log("objec to map", objectMap);
    for (let entry of data) {
      let pushableObj = {};
      for (const key in objectMap) {
        if (Object.hasOwnProperty.call(objectMap, key)) {
          const value = _.get(entry, objectMap[key], "NA");
          pushableObj[key] =
            typeof value === "string"
              ? value.includes("000Z")
                ? moment(value).format("DD/MM/YYYY")
                : value
              : value;
        }
      }
      preparedData.push(pushableObj);
    }

    const worksheet = XLSX.utils.json_to_sheet(preparedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${name}.xlsx`);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
};
